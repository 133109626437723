import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 90vw;
  min-height: 100vh;
  display: flex;
  font-size: 16px;
  max-width: 800px;
  line-height: 1.6em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;

  h2 {
    text-transform: uppercase;
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: 600;
  }

  ol {
    line-height: 2em;
  }
`;

const Rules = () => (
  <Wrapper>
    <h2>How It Works</h2>
    <ol>
      <li>
        Follow{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/trapkaraoke"
        >
          @TrapKaraoke
        </a>{" "}
        on Instagram to find out the next time we go live for Caption This Live
      </li>
      <li>
        Join{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/trapkaraoke"
        >
          @TrapKaraoke
        </a>{" "}
        IG Live when we go live for Caption This Live
      </li>
      <li>At the start of each round, participants will be shown a meme.</li>
      <li>
        Participants will have 60 to 90 seconds to leave their caption in the
        comments section.
      </li>
      <li>
        After the round ends, the host will pick a winner from the comments.
        Winners will receive $50 for each winning caption.
      </li>
      <li>There will be a total of 10 rounds w/ 1 winner per round</li>
      <li>
        The funnier and more relatable your caption, the greater your chance of
        winning, so bring your A-game!
      </li>
      <li>
        Check the <Link to="/rules">Rules</Link> for the official Rules of Play.
      </li>
      <li>Contact us via this website if you have any questions.</li>
    </ol>
  </Wrapper>
);

export default Rules;
