import React, { Component } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { device } from "../utils/devices";

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    margin: 0 auto;
    width: 40vw;
  }

  .player {
    width: auto !important;
    margin: 0 auto;
    height: 90vh !important;

    @media ${device.laptop} {
      height: 90vh !important;
    }

    video {
      object-fit: contain;
      outline: none;
    }
  }
`;

class Video extends Component {
  render() {
    return (
      <Wrapper>
        <ReactPlayer
          className="player"
          url={this.props.url}
          playing={true}
          playsInline
          loop
          volume={0}
          config={{
            file: {
              attributes: {
                playsInline: true,
                autoPlay: true,
                muted: true
              }
            }
          }}
        />
      </Wrapper>
    );
  }
}

export default Video;
