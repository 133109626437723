import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import About from "./pages/About";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Rules from "./pages/Rules";
import HowItWorks from "./pages/HowItWorks";

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/rules" component={Rules} />
          <Route exacth path="/how-it-works" component={HowItWorks} />
        </Switch>
      </div>
    );
  }
}

export default App;
