import React from 'react';
import styled from 'styled-components';

const Page = styled.div`
  width: 90vw;
  max-width: 600px;
  margin: 8rem auto 4rem;
  min-height: calc(100vh - 8rem);
  display: flex;
  align-items: center;
  line-height: 1.5em;
  font-size: 18px;
`;

const About = (props) => (
  <Page>
    <article>
      <p>Caption This Live was inspired by the evolution of meme culture. Memes are not just jokes but an entire mode of communication.</p>
      <p>The ability to infer other people’s emotions from their facial expressions and situational cues is at the core of social communication. In that capacity, memes function as an indicator for emotional intelligence. Just like singing or telling jokes, emotional intelligence is a skill that can be honed and developed but some people are just more skilled at it than others.</p>
      <p>Pairing emotional intelligence with snark, wit, cultural competence, internet access, and comedic timing, these prolific meme masters are pioneers of a new type of comedic art-form responsible for creating viral sensations.</p>
      <p>Caption This Live is an event series where meme masters showcase their skills by facing off in front of a live audience in games that are a mix of memes meets improv. For fans of Black Twitter’s prolific meme culture, Caption This Live takes this virtual experience and turns it into a real life, communal experience you can enjoy with other fans.</p>
    </article>
  </Page>
);

export default About;
