import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../logo.svg";
import { device } from "../utils/devices";
import { withRouter } from "react-router-dom";

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 2rem 5vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;

  @media ${device.laptop} {
    justify-content: space-between;
    flex-direction: row;
    padding: 2rem;
  }

  .logo {
    width: 30vw;

    @media ${device.laptop} {
      width: 7vw;
    }
  }

  img {
    width: 100%;
  }

  nav {
    display: flex;
    justify-content: space-between;
    margin: 2rem auto 0;

    @media ${device.laptop} {
      margin: 0;
      width: auto;
    }
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 1rem;

    @media ${device.laptop} {
      font-size: 16px;
    }

    &:last-of-type {
      margin: 0;
    }

    &:hover {
      color: #00bcd4;
    }
  }
`;

const Header = ({ location }) => (
  <Wrapper>
    <Link className="logo" to="/">
      <img src={logo} alt="Caption This Live" />
    </Link>
    <nav>
      <Link to="/about">About</Link>
      <Link to="/rules">Rules</Link>
      <Link to="/how-it-works">How It Works</Link>
      <Link to="/contact">Contact</Link>
    </nav>
  </Wrapper>
);

export default withRouter(Header);
