import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 90vw;
  min-height: 100vh;
  display: flex;
  font-size: 16px;
  max-width: 800px;
  line-height: 1.6em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;

  a {
    color: black;
  }

  ol {
    line-height: 2em;
  }
`;

const Rules = () => (
  <Wrapper>
    <h2>DISCLAIMERS & RULES OF PLAY</h2>
    <p>
      Trap Karaoke LLC its hosts, contractors, assigns, agents, and sponsors aim
      to create a fun online user experience sharing publicly available and
      viral memes. Trap Karaoke LLC in no way asserts ownership or rights other
      than those granted by source public platforms and its users (i.e.
      facebook, twitter, instagram, tiktok, snapchat) to reshare, republish, and
      broadcast the images, videos, and memes displayed during and live stream.
      This contest is in no way sponsored, endorsed, or administered by
      Instagram, Facebook Inc, Twitter Inc, or any other platform advertising or
      content may appear on.
    </p>
    <p>
      Participants who comment during the live stream accept and agree to be
      legally bound to the following rules of contest play
    </p>
    <ol>
      <li>
        All livestreams will be conducted via Instagram Live utilizing the
        handle{" "}
        <a
          target="_blank"
          rel="noopener noreferrer nofollow"
          href="https://instagram.com/trapkaraoke"
        >
          @trapkaraoke
        </a>
      </li>
      <li>
        All live streams are viewable and open to the public to watch and
        participate. ("participant(s)")
      </li>
      <li>
        No purchase is necessary. No prior or future purchase will increase
        chances of winning this contest
      </li>
      <li>
        Contest is limited to United States residents, with an authorized
        Instagram account, over the legal age of 18.
      </li>
      <li>Contest void where prohibited</li>
      <li>
        No cash single cash prize shall exceed $500 in value. Total prizes
        combined value shall not exceed $1,000 in value.
      </li>
      <li>
        Contests will take place in 60 second rounds in which a image/video will
        be displayed and users may offer captions via the comment function
        ("Rounds"){" "}
      </li>
      <li>
        Participants agree and warrant that all captions and comments are
        original works of authorship and do not infringe or violate the rights
        of any third party and agree to indemnify and hold harmless Trap Karaoke
        LLC in the event such claim arise.
      </li>
      <li>
        Participants found to be abusive or harassing towards any individual or
        group of individuals regarding race, sex, religion, creed, national
        origin, age, disability, gender identity or expression, sexual
        orientation or any legally protected classification (which includes, but
        is not limited to, epithets or slurs, threats, intimidations, or hostile
        acts) shall be immediately removed and disqualified from the contest.
      </li>
      <li>
        Participants agree and grant all rights to Trap Karaoke LLC to announce
        winning profile accounts, redisplay and utilize in any format submitted
        captions and comments
      </li>
      <li>
        The host in his/her sole discretion will select one (1) winner per Round
      </li>
      <li>
        Winner of each Round will be the person authoring the best caption
        ("winner")
      </li>
      <li>
        All winners must provide copies of valid government ID and have a valid
        CashApp account to receive any cash prize
      </li>
      <li>
        Any winner who is not present for selection at the end of a Round, is
        under the legal age of 18, cannot provide a copy of valid government ID
        upon request, or does not have a valid CashApp account shall forfeit any
        prize
      </li>
      <li>
        An individual may win only once (1) every seven (7) days and no more
        than twice (2) in any consecutive 30 day period.
      </li>
      <li>
        Should multiple users of the same Instagram account as applicable, enter
        the contest and a dispute thereafter arise regarding the identity of the
        participant, the authorized account holder of said Instagram account
        must provide proof of ownership at the time of entry to be considered
        the participant
      </li>
    </ol>
    <p>
      By participating, you agree (a) to be bound by these Official Contest
      Rules; (b) as between you and Trap Karaoke, the decisions of Trap Karaoke
      are final on all matters relating to the Contest; (c) you are not
      participating on behalf of any employer or third party; (d) in the event
      that you do not comply with these rules, that you will be disqualified and
      your prize (if any) will be forfeited. ALL DECISIONS REGARDING THE
      APPROPRIATENESS OF SUBMITTED CAPTIONS AND COMMENTS SHALL BE AT THE SOLE
      DISCRETION OF TRAP KARAOKE LLC. Trap Karaoke LLC reserves the right to
      reject any caption or comment, in its sole discretion, based on the terms
      set forth herein as well as current internet standards and other terms of
      service policies established by utilized display platforms. Any waiver of
      any obligation hereunder by Trap Karaoke does not constitute a general
      waiver of any obligation to participants. Sponsor reserves the right to
      waive the Contest Content requirements set forth herein in its reasonable
      discretion. Trap Karaoke reserves the right, in its reasonable discretion,
      during or upon completion of a contest, to request that any winner
      resubmit his or her information which fails to comply with the rule
      requirements prior to disbursement of any prize. Any valuation of the
      prize(s) stated above is based on available information provided to Trap
      Karaoke, and the value of any prize awarded to a winner may be reported to
      the IRS as required by law. Each winner is solely responsible for
      reporting and paying any and all applicable federal, state, and local
      taxes, related to prize acceptance and use not specified herein.
    </p>
    <p>
      PARTICIPANT CREATED CONTENT POSTED TO THE WEBSITE WAS NOT EDITED BY
      SPONSOR AND IS THE VIEWS/OPINIONS OF THE INDIVIDUAL PARTICIPANT AND DOES
      NOT REFLECT THE VIEWS OF TRAP KARAOKE OR ITS SPONSORS IN ANY MANNER. Trap
      Karaoke LLC reserves the right to terminate the contest if in its sole
      judgment, the rules or the integrity of the contest have been violated or
      compromised in any way, intentionally or unintentionally by any person
      whether or not a winner in the contest. If you think that any content
      utilized in the contest infringes your intellectual property rights,{" "}
      <Link to="/contact">CLICK HERE</Link> if you wish to report it, or contact
      us by mail at DMCA Designated Agent, c/o Legal Department, Trap Karaoke
      LLC, 1055 West 7th Street 33rd Floor PH Los Angeles Ca. 90017 or by email
      at <a href="mailto:legal@trapkaraoke.com">legal@trapkaraoke.com</a>. All
      issues and questions concerning the construction, validity, interpretation
      and enforceability of the contest official rules, or the rights and
      obligations of participants and Trap Karaoke LLC in connection with the
      contest, shall be governed by, and construed in accordance with, the laws
      of the State of Georgia, U.S.A. wherein participation in this contest
      constitutes agreement and waiver of venue, choice of law, or any effect of
      conflict of laws rules that may normally apply to controversies.
    </p>
  </Wrapper>
);

export default Rules;
