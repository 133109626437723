import React from "react";
import Video from "../components/Video";
import video from "../video/caption-this.mov";

const Home = props => (
  <div>
    <Video url={video} />
  </div>
);

export default Home;
